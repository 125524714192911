
.flex-child-element {
  float: left;
  margin: 10px;
}

.flex-child-element:first-child {
  float: right;
  margin-right: 20px;
}
label {display: block;}
textarea {display: block;}