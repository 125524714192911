.button {
    background-image: linear-gradient(#bae6fd, #0ea5e9 );
    border-color: transparent;
    border-radius: 3px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.noButton {
    display: none;
}