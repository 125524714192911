.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-bubble {
  position: absolute;
  top: -5px;
  left: 105%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  overflow: auto;
}

.tooltip-container:hover .tooltip-bubble {
  opacity: 1;
}
