form {
  margin: 3rem auto;
  width: 500px;
  max-width: 80%;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
form > div {
  display: flex;
}
.input-group {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
.input-group > label {
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
}
.input {
  width: 100%;
  display: flex;
}
.input > input {
  padding: 0.3rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
}
.input > input:focus {
  outline: none;
}
.input > div {
  padding: 0.5rem 1rem;
  background: #e34728;
  margin-left: 0.5rem;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.add-btn {
  padding: 0.5rem 1rem;
  background: #ccc;
  border: none;
  font-size: 0.9rem;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.center {
  display: flex;
  justify-content: center;
}
.submit-btn {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #e34728;
  background: #fff;
  margin: 1.2rem 0;
  color: #e34728;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
}
.dialog-box {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  grid-gap: 1rem;
}
.dialog-box > input,
.dialog-box > select {
  padding: 0.5rem 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.dialog-box > input:focus,
.dialog-box > select:focus {
  outline: none;
}
.hide {
  display: none;
}
