header {
  background-color: white;
  color: white;
  padding: 45px;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
}

nav li {
  margin-right: 4rem;
}

nav a {
  color: black;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;

}

