/* Footer.css */

footer {
  background-color: #333;
  color: white;
  text-align: center;
  bottom: auto;
  height: auto;
  display: flow-root;
  width: 100%;
}
