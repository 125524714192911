.news-ticker {
  height: 200px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.news-ticker ul {
  padding: 0;
  margin: 0;
  list-style: none;
  animation: ticker 30s linear infinite;
}

.news-ticker li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

@keyframes ticker {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
