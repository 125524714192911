.modal {
    position: fixed;
    top: 5%;
    left: 20%;
    width: 50%;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
}

.content {
      top: 40px;
      left: 40px;
      right: 40px;
      bottom: 40px;
      border: 1px solid #ccc;
      background: #fff;
      overflow: auto;
      WebkitOverflowScrolling: touch;
      borderRadius: 4px;
      outline: none;
      padding: 20px
    }

.modal-main {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}
